import { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import CustomCarousel from "../../common/Carousel/Carousel";
import Footer from "../../common/Footer/Footer";
import TickerSlider from "../../common/TickerSlider/TickerSlider";
import Social from "../Social/Social";
import "./HomePage.scss";
import Products from "./Products";
import Statistics from "./Statistics/Statistics";
import Trust from "./Trust";
import { motion, Variants } from "framer-motion";
import { Link } from "react-router-dom";

const HomePage = () => {
  const { theme } = useAppSelector((state) => state.theme);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const videoSrc =
    theme === "light"
      ? isMobile
        ? "https://saitadex-deepak.s3.us-east-2.amazonaws.com/Elements/SW_5_Light_Mobile.mp4"
        : "https://saitadex-deepak.s3.us-east-2.amazonaws.com/Elements/SW_5_Light_Mode.mp4"
      : isMobile
      ? "https://saitadex-deepak.s3.us-east-2.amazonaws.com/Elements/SW_5_Dark_Mobile.mp4"
      : "https://saitadex-deepak.s3.us-east-2.amazonaws.com/Elements/SW_5_Dark_Mode.mp4";

  const cardVariants: Variants = {
    offscreen: {
      x: 300,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      // rotate: -10,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <motion.div
        className="hero-container"
        // initial="offscreen"
        // whileInView="onscreen"
        // viewport={{ once: true, amount: 0.8 }}
        initial={{ opacity: 0, x: 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{
          duration: 0.5,
          opacity: { duration: 1, delay: 0.5 }, // Adding delay for opacity
          x: { duration: 0.5 }, // Duration for x transition
        }}
      >
        <video
          key={theme + isMobile}
          className="video-file"
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
        <motion.section
          className="hero"
          // variants={cardVariants}
        >
          <div className="hero__content">
            <div className="hero__welcome">Welcome to SaitaSwap!</div>
            <div className="hero__title">Swap up Your Crypto Game!</div>
            <div className="hero__description">
              Connect your wallet now to unlock seamless swaps, secure staking,
              and unparalleled financial empowerment across multiple
              blockchains.
            </div>
            <Link to="/swap">
              <button className="hero__button">Swap Now!</button>
            </Link>
          </div>
        </motion.section>
      </motion.div>
      <CustomCarousel />
      <TickerSlider />
      {/* <Trust /> */}
      <Products />
      <Statistics />
      <Social />
      <Footer />
    </>
  );
};

export default HomePage;
