import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import {
  CheckIcon,
  CurrencyEthereum,
  MenuMain,
  MoonIcon,
  SunIcon,
} from "../../../assets/icons/svgicons";
import "./Sidebar.scss";
// import { BridgeIcon, FarmIcon, LiquidityIcon, MenuIcon, PoolIcon, SwapIcon, TradeIcon } from '../../../assets/icons/svgicons'
import logo from "../../../assets/logo/logo.svg";
import lightLogo from "../../../assets/logo/light-logo.svg";
import { useAppSelector } from "../../../app/hooks";
import { ROUTES } from "../../../utils/constants";
import { useEffect, useRef, useState } from "react";
import { toast } from "../Toasts/Toast";
import { DropDownArrow } from "../../../assets/icons/svgicons";
import { FaTimes } from "react-icons/fa"; // Import close icon
import { RiArrowDropDownLine } from "react-icons/ri";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { setTheme } from "../../../features/theme/theme.slice";

type propTypes = {
  active?: boolean;
  handleActive?: any;
  setRouteDetail?: any;
};

const Sidebar = (props: propTypes) => {
  const { symbol } = useParams();
  const dispatch = useDispatch();
  const { walletAddress } = useAppSelector((state: any) => state?.user);
  const tokenSymbol = useAppSelector((state: any) => state.token.symbol);
  const tokenAddress = useAppSelector((state: any) => state.token.address);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);
  const [showTradeDropDown, setShowTradeDropDown] = useState(false);
  const [showEarnDropDown, setShowEarnDropDown] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isMobileTheme, setIsMobileTheme] = useState(window.innerWidth < 768);

  const handleChange = () => {
    dispatch(setTheme(theme === "dark" ? "light" : "dark"));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1199);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileTheme(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // if (!walletAddress) toast?.info("Please connect your wallet first!");
  }, [activeTab]);

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  const handerTradeDropDown = () => {
    setShowTradeDropDown(!showTradeDropDown);
    setShowEarnDropDown(false);
  };

  const handerEarnDropDown = () => {
    setShowEarnDropDown(!showEarnDropDown);
    setShowTradeDropDown(false);
  };

  const { theme } = useAppSelector((state) => state.theme);

  const navs = [
    {
      label: "Home",
      to: ROUTES.HOME,
    },
    {
      label: "Trade",
      to: "",
    },
    {
      label: "Earn",
      to: "",
    },
    {
      label: "Liquidity",
      to: ROUTES.LIQUIDITY,
    },
    {
      label: "XBridge",
      to: ROUTES.XBRIDGE,
    },
  ];
  const iconStyles = {
    color: theme === "light" ? "#007BFF" : "white",
    cursor: "pointer",
  };
  const sidebarItemStyles = {
    // backgroundColor: theme === "light" ? "#fff" : "#000",
    color: theme === "light" ? "#000" : "#fff",
    // marginBottom: '1px',
    // borderBottom: theme === "light" ? "1px solid #c0bebe" : "1px solid #3e3b3b",
    width: "100%",
  };
  const TradeItemStyles = {
    padding: theme === "dark" ? "5px 0px 5px 0px" : "5px 0px 5px 0px",
    backgroundColor: theme === "light" ? "#f3f3f3" : "#191919",
    width: "100%",
  };

  interface AccordionItemProps {
    faq: FAQ;
    active: number | null;
    handleToggle: (id: number) => void;
    handerTradeDropDown: () => void;
    handleActive: () => void;
    handleLinkClick: (to: string) => void;
  }

  interface LinkItem {
    to: string;
    text: string;
  }

  interface FAQ {
    header: string;
    id: number;
    links: LinkItem[];
  }

  const faqs: FAQ[] = [
    {
      id: 1,
      header: "Simple Trades",
      links: [
        { to: "/trade", text: "Simple Trades" },
        { to: "/swap", text: "Swap" },
        { to: "/cross-chain", text: "Cross Chain" },
        // { to: "/explorer", text: "Explorer" },
      ],
    },
    {
      id: 2,
      header: "Earn",
      links: [
        { to: "/staking-pool", text: "Staking" },
        { to: "/farm", text: "Farm" },
      ],
    },
  ];

  const AccordionItem: React.FC<AccordionItemProps> = ({
    faq,
    active,
    handleToggle,
    handerTradeDropDown,
    handleActive,
    handleLinkClick,
  }) => {
    const contentEl = useRef<HTMLDivElement>(null);
    const { header, id, links } = faq;

    useEffect(() => {
      if (contentEl.current && active === id) {
        contentEl.current.style.height = `${contentEl.current.scrollHeight}px`;
      } else if (contentEl.current) {
        contentEl.current.style.height = "0px";
      }
    }, [active, id]);

    return (
      <div className="rc-accordion-card">
        <div className="rc-accordion-header">
          <div
            className={`rc-accordion-toggle ${active === id ? "active" : ""}`}
            onClick={() => handleToggle(id)}
          >
            <h5 className="rc-accordion-title">{header}</h5>
            {/* <RiArrowDropDownLine className="rc-accordion-icon" /> */}
            <FontAwesomeIcon
              icon={faChevronDown}
              className="rc-accordion-icon"
            />
          </div>
        </div>
        <div
          ref={contentEl}
          className={`rc-collapse ${active === id ? "show" : ""} `}
        >
          <div className="rc-accordion-body">
            {links.map((link: any, index: any) => (
              <div key={index} className={`accordion-body accordion-Item`}>
                <p>
                  <NavLink
                    to={link.to}
                    onClick={() => {
                      handerTradeDropDown();
                      handleActive();
                      handleLinkClick(link.to);
                    }}
                  >
                    {link.text}
                  </NavLink>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const [active, setActive] = useState<any | null>(null);

  const handleToggle = (index: number) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const handleLinkClick = (to: string) => {
    console.log(to, "to");
    console.log(location.pathname, "current location");

    if (!(isMobile && to.trim() !== location.pathname.trim())) {
      console.log("Navigating to a different page, closing accordion.");
      setActive(null);
    } else {
      console.log("On the same page, keeping accordion open.");
    }
  };

  // useEffect(() => {
  //   setActive(null);
  // }, [props.handleActive]);

  return (
    <>
      <div
        onClick={props.handleActive}
        className={`${props.active ? "active" : ""} sidebar_overlay d-xl-none`}
      />
      <aside className={`sidebar ${props.active ? "active" : ""}`}>
        <div className={"sidebar_head"}>
          <img
            src={theme === "light" ? lightLogo : logo}
            alt="saitaswap logo"
          />
          <FaTimes
            className="close-icon"
            style={iconStyles}
            onClick={props.handleActive}
          />
        </div>
        {/* <ul>
          {navs?.map((item) => {
            return (
              <li key={item.label}>
                <Link
                  onClick={() => {
                    props?.setRouteDetail(item?.to);
                    props?.handleActive();
                    setActiveTab(item?.to);
                  }}
                  target={item?.label!="XBridge"?"":"_blank"}
                  to={item?.label!="XBridge"?item.to:"https://xbridge.tech/"}
                  className={activeTab == item?.to ? "active" : ""}
                >
                  {item.label}
                </Link>
              </li>
            );
          })}
        </ul> */}
        {isMobileTheme && (
          <>
            <div className="row themsClass">
              <div className="col-md-12 d-flex justify-content-end">
                <div className="themButton">
                  <button
                    className={`theme_btn ${theme === "dark" ? "active" : ""}`}
                    onClick={handleChange}
                  >
                    <motion.div layout>
                      <AnimatePresence mode="popLayout">
                        {theme === "dark" ? (
                          <motion.div
                            key={1}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                          >
                            <SunIcon />
                          </motion.div>
                        ) : (
                          <motion.div
                            key={2}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                          >
                            <MoonIcon />
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </motion.div>
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        <ul>
          {navs.map((item) => (
            <li
              key={item.label}
              style={{
                ...(isMobile ? sidebarItemStyles : {}),
              }}
              className="sidebarList"
            >
              {item.label === "Trade" ? (
                <div
                  className="dropdown-nav-item"
                  onMouseEnter={handerTradeDropDown}
                  onMouseLeave={handerTradeDropDown}
                >
                  {!isMobile ? (
                    <Link to="#">
                      <div className="dropdown-nav-value">
                        <p>Trade</p> <DropDownArrow />
                      </div>
                    </Link>
                  ) : (
                    ""
                  )}

                  {!isMobile && showTradeDropDown && (
                    <div className="drop-down">
                      <p>
                        <NavLink to="/trade" onClick={handerTradeDropDown}>
                          Simple Trades
                        </NavLink>
                      </p>
                      <p>
                        <NavLink to="/swap" onClick={handerTradeDropDown}>
                          Swap
                        </NavLink>
                      </p>
                      <p>
                        <NavLink
                          to="/cross-chain"
                          onClick={handerTradeDropDown}
                        >
                          Cross Chain
                        </NavLink>
                      </p>
                      {/* <p>
                        <NavLink to="/explorer" onClick={handerTradeDropDown}>
                          Explorer
                        </NavLink>
                      </p> */}
                    </div>
                  )}

                  {isMobile ? (
                    <>
                      <div className="">
                        {faqs.map((faq) => (
                          <AccordionItem
                            key={faq.id}
                            faq={faq}
                            active={active}
                            handleToggle={handleToggle}
                            handerTradeDropDown={handerTradeDropDown}
                            handleActive={props.handleActive}
                            handleLinkClick={handleLinkClick}
                          />
                        ))}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : item.label === "Earn" ? (
                <div
                  className="dropdown-nav-item"
                  onMouseEnter={handerEarnDropDown}
                  onMouseLeave={handerEarnDropDown}
                >
                  {!isMobile ? (
                    <Link to="#">
                      <div className="dropdown-nav-value">
                        <p>Earn</p> <DropDownArrow />
                      </div>
                    </Link>
                  ) : (
                    ""
                  )}
                  {!isMobile && showEarnDropDown && (
                    <div className="drop-down">
                      <p>
                        <NavLink
                          to="/staking-pool"
                          onClick={handerEarnDropDown}
                        >
                          Staking
                        </NavLink>
                      </p>
                      <p>
                        <NavLink to="/farm" onClick={handerEarnDropDown}>
                          Farm
                        </NavLink>
                      </p>
                      {/* <p>
                        <NavLink to="/liquidity" onClick={handerEarnDropDown}>
                          Liquidity
                        </NavLink>
                      </p> */}
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  onClick={() => {
                    props?.setRouteDetail(item?.to);
                    props?.handleActive();
                    setActiveTab(item?.to);
                  }}
                  target={item?.label !== "XBridge" ? "" : "_blank"}
                  to={
                    item?.label !== "XBridge"
                      ? item.to
                      : "https://xbridge.tech/"
                  }
                  className={activeTab === item?.to ? "active" : ""}
                >
                  {item.label}
                </Link>
              )}
            </li>
          ))}

          {!(location.pathname === "/") ? (
            <li
              className="list-for-tradef"
              style={{
                ...(isMobile ? TradeItemStyles : {}),
              }}
            >
              <div className="connect_btnf">
                <Link
                  className="app-buttonf"
                  to="/token/MOTN/0x19Ae49B9F38dD836317363839A5f6bfBFA7e319A"
                  onClick={() => {
                    props.handleActive();
                  }}
                >
                  Trade History
                </Link>
              </div>
            </li>
          ) : (
            ""
          )}
        </ul>
      </aside>
    </>
  );
};

export default Sidebar;
