import React, { useState, useEffect } from "react";
import { Pagination } from "react-bootstrap";
import "./pagination.css";

interface PaginationComponentProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (pageNumber: number) => void;
  theme: string;
}

const PaginationComponent: React.FC<PaginationComponentProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  theme
}) => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    }
  };

  const renderPaginationItems = () => {
    const pageItems = [];
    const maxVisiblePages = 3;
    const startPage = Math.max(
      1,
      currentPage - Math.floor(maxVisiblePages / 2)
    );
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

    if (startPage > 1) {
      pageItems.push(
        <Pagination.Item
          key={1}
          onClick={() => handlePageChange(1)}
          className={`${theme}`}
        >
          1
        </Pagination.Item>
      );
      if (startPage > 2) {
        pageItems.push(
          <Pagination.Ellipsis
            key="start-ellipsis"
            className={`dotButton ${theme === "light" ? "lightTheme" : "darkTheme"}`}
          />
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
          className={`${theme}`}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageItems.push(
          <Pagination.Ellipsis
            key="end-ellipsis"
            className={`dotButton ${theme === "light" ? "lightTheme" : "darkTheme"}`}
          />
        );
      }
      pageItems.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={`${theme}`}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return pageItems;
  };

  return (
    <div className="pagination-container">
      <Pagination className="justify-content-center">
        {isMobileView ? (
          <>
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`${theme}`}
            />
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`${theme}`}
            />
          </>
        ) : (
          <>
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
              className={`${theme}`}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={`${theme}`}
            />
            {renderPaginationItems()}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={`${theme}`}
            />
            <Pagination.Last
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
              className={`${theme}`}
            />
          </>
        )}
      </Pagination>
    </div>
  );
};

export default PaginationComponent;
