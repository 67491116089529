import React from "react";
import { CiStar } from "react-icons/ci";
import { IoShareSocialOutline } from "react-icons/io5";
import { TbStarFilled } from "react-icons/tb";
import "./DashboardHeader.scss";
import { useAppSelector } from "../../../../app/hooks";
import { NetworkTypes } from "../../../../interfaces/common";
import { useSelector } from "react-redux";

interface CoinData {
  tokenName: string;
  tokenSymbol: string;
  totalSupply: number;
  circulatingSupply: number;
  price: number | null;
  circulationMarketCap: number;
  volume24h: number | null;
  logo: string;
}
interface CoinData2 {
  volume24hr: number | null;
  latestPrice: number | null;
}

interface DashboardHeaderProps {
  coinData: CoinData | null;
  coinData2: CoinData2 | null;
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  coinData,
  coinData2,
}) => {
  const { theme } = useAppSelector((state) => state.theme);
  const [favourite, setFavourite] = React.useState(false);
  const selectedChain: NetworkTypes = useSelector((state: any) => {
    const symbol = state?.user?.chainValues?.symbol;
    return symbol === "STC" ? "SBC" : symbol;
  });

  //console.log(coinData, "@@@@@")
  const handleFavourite = () => {
    setFavourite(!favourite);
  };

  return (
    <div
      className={`dashboard-header-container ${
        theme === "light" ? "lightTheme" : "darkTheme"
      }`}
    >
      <div className="left-section">
        <div className="dashboard-logo-container">
          <img
            src={coinData?.logo || "https://via.placeholder.com/24"}
            alt="coin"
          />
          <div className="logo-text">
            <div>{coinData?.tokenSymbol || "N/A"}</div>
            <div className="subtext">
              {coinData?.tokenName || "N/A"} / {`${selectedChain || ""}`}
            </div>
          </div>
        </div>
      </div>

      <div className="right-section">
        <div
          className={`price-container ${
            theme === "light" ? "lightText" : "darkText"
          }`}
        >
          {/* ${coinData?.price?.toFixed(10) || " N/A"} */}
          {((selectedChain as any) === "BSC" ||
            (selectedChain as any) === "ETH") &&
          coinData2?.latestPrice !== null &&
          coinData2?.latestPrice !== undefined
            ? `$${coinData2.latestPrice.toFixed(10)}`
            : coinData?.price !== null && coinData?.price !== undefined
            ? `$${coinData.price.toFixed(10)}`
            : "N/A"}
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
