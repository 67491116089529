import React, { useEffect, useState } from "react";
import "./history.css";
import { Table, Spinner, Alert, Pagination } from "react-bootstrap";
import { useAppSelector } from "../../../../app/hooks";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaFilter, FaCopy } from "react-icons/fa6";
import { LiaSortSolid } from "react-icons/lia";
import axios from "axios";
import { NetworkTypes } from "../../../../interfaces/common";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { setCurrentSymbol } from "../../../../features/theme/token.slice";
import PaginationComponent from "../Pagination/Pagnination";

interface TokenParams extends Record<string, string | undefined> {
  symbol: string;
}

interface TradeHistoryProps {
  showToken: boolean;
  setShowToken: (value: boolean) => void;
}

const TradeHistorySection: React.FC<TradeHistoryProps> = ({
  showToken,
  setShowToken,
}) => {
  const { theme } = useAppSelector((state) => state.theme);
  const { symbol: urlSymbol, address } = useParams<TokenParams>();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isCopied, setIsCopied] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  //console.log(totalPages, "totalPages");

  // const selectedChain: NetworkTypes = useSelector((state: any) => {
  //   const symbol = state?.user?.chainValues?.symbol;
  //   return symbol === "STC" ? "SBC" : symbol;
  // });

  const selectedChain = "SBC";

  const [currentSymbol, setCurrentSymbol] = useState("");

  const navigate = useNavigate();
  // const currentSymbol = useSelector((state: any) => state.token.currentSymbol);

  const getDefaultSymbol = (): string => {
    if ((selectedChain as any) === "BSC") return "BNB";
    if ((selectedChain as any) === "ETH") return "ETH";
    if ((selectedChain as any) === "SBC") return "MOTN";
    return "";
  };

  // UseEffect to fetch data when showToken is false and urlSymbol is available
  const fetchData = async (page: number, symbol: string) => {
    console.log(page, "Page");
    setIsLoading(true);
    setError(null);
    try {
      let sybol = symbol;
      if ((selectedChain as any) == "SBC" && symbol == "MOTN") {
        sybol = "MOTN";
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/coin/transaction/history?chain=${selectedChain}&symbol=${sybol}&page=${page}&limit=${itemsPerPage}`
      );

      // console.log(response.data.data.length, "9999");
      if (response.data.data.length === 0) {
        setError("Trade history data not found!");
      } else {
        setData(response?.data?.data);
        console.log(data, "data;");
        setTotalPages(response?.data?.totalPages || 1);
      }
    } catch (err) {
      setError("Trade history data not found!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!showToken && urlSymbol) {
      setCurrentSymbol(urlSymbol);
      fetchData(currentPage, urlSymbol);
    }
  }, [urlSymbol, currentPage, showToken]);

  useEffect(() => {
    setCurrentPage(1);
  }, [showToken, selectedChain]);

  const columns = [
    {
      label: "Date",
      // icon: <RiArrowDropDownLine fontSize={18} fontWeight={600} />,
    },
    {
      label: "Buy/Sale",
      // icon: <FaFilter />
    },
    {
      label: "Token Price",
      // icon: (
      //   <>
      //     <LiaSortSolid fontSize={13} fontWeight={600} /> <FaFilter />
      //   </>
      // ),
    },
    {
      label: "Amount STC",
      // icon: (
      //   <>
      //     <LiaSortSolid fontSize={13} fontWeight={600} /> <FaFilter />
      //   </>
      // ),
    },
    {
      label: `Amount ${urlSymbol}`,
      // icon: (
      //   <>
      //     <LiaSortSolid fontSize={13} fontWeight={600} /> <FaFilter />
      //   </>
      // ),
    },
    {
      label: "Transaction hash",
    },
    {
      label: "Maker",
      //  icon: <FaFilter />
    },
  ];

  const formatMakerAddress = (address: string) =>
    `${address.slice(0, 4)}...${address.slice(-4)}`;

  // const formatDate = (timestamp: number) => new Date(timestamp * 1000).toLocaleString();

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    return date.toLocaleDateString("en-US", options).replace(",", "");
  };

  const tableTdColor = theme === "light" ? "lightTd" : "darkTd";
  const copyAddress = async (address: string) => {
    try {
      await navigator.clipboard.writeText(address);
      console.log("Copied address:", address);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {(selectedChain as any) === "SBC" && (
        <div className="info-section">
          <div className="table-container">
            <div
              className={`table-wrapper ${
                theme === "light" ? "dark-border" : ""
              }`}
            >
              <Table
                responsive
                className={`table ${theme === "dark" ? "table-dark" : "white"}`}
              >
                <thead>
                  <tr>
                    {columns?.map((col, index) => (
                      <th
                        key={index}
                        className={`${
                          theme === "dark" ? "tablehead" : "theadwhite"
                        }`}
                      >
                        {col.label && <span>{col.label}</span>}
                        {/* {col.icon} */}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan={12}>
                        <div className="loading-container">
                          <Spinner
                            animation="border"
                            role="status"
                            variant={theme === "dark" ? "light" : "dark"}
                          >
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div>
                      </td>
                    </tr>
                  ) : error ? (
                    <tr>
                      <td colSpan={12}>
                        <div className="error-container">
                          <Alert className="error-message">{error}</Alert>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {data?.map((row) => (
                        <tr
                          key={row.id}
                          className={`tableData ${
                            row.transactionType === "buy"
                              ? "buy-row"
                              : "sell-row"
                          } ${tableTdColor}`}
                        >
                          <td>{formatDate(row.timestamp)}</td>
                          <td
                            className={`pe-4 ${
                              row.transactionType === "buy"
                                ? "BuyColor"
                                : "changeColorOne"
                            }`}
                          >
                            {row.transactionType}
                          </td>
                          <td>${row.tokenPrice.toFixed(10)}</td>
                          <td>{row.amountSTC && Math.abs(row.amountSTC)}</td>
                          <td>
                            {row.amountToken && Math.abs(row.amountToken)}
                          </td>
                          <td className="changeColorHash">
                            <Link
                              to={`https://saitascan.io/tx/${row.transactionHash}`}
                              target="_blank"
                            >
                              {formatMakerAddress(row.transactionHash)}
                            </Link>
                          </td>
                          <td className="changeColorTwo">
                            {formatMakerAddress(row.maker)}{" "}
                            <FaCopy
                              color="#818EA3"
                              onClick={() => copyAddress(row.maker)}
                              style={{ cursor: "pointer" }}
                            />
                            {isCopied && (
                              <span className="copied-message">
                                Address copied!
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </div>

          {/* Pagination Section */}
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            theme={theme}
          />
        </div>
      )}
    </>
  );
};

export default TradeHistorySection;
