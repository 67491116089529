import React from "react";
import Lottie from "lottie-react";
// import glowbg from "../../../assets/animations/glow_bg.json";
import glowbg from "../../../assets/animations/glow_bg.json";
import "./Social.scss";
import {
  Facebook,
  Instagram,
  Linkedin,
  Telegram,
  Twitter,
} from "../../../assets/icons/svgicons";
import { motion } from "framer-motion";

const Social = () => {
  return (
    <motion.div
      className="community-layout"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{
        opacity: { duration: 1, delay: 0.5 }, // Adding delay for opacity
      }}
    >
      <motion.div
        className="text-section"
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        <h2>EMPOWERED BY COMMUNITY</h2>
        <p>
          Our community is the driving force behind SaitaSwap. Your support and
          engagement fuel our innovation, making every aspect of our platform
          stronger and more dynamic. Join us and help shape the future.
        </p>
        <h4>Get social with us!</h4>
        <div className="social-icons">
          <a
            href="https://x.com/SaitaChainCoin?t=a3CEIVO0BUl093GQo6Lqjw&s=08"
            target="_blank" // Opens the link in a new tab
            rel="noopener noreferrer" // Security reasons
          >
            <Twitter />
          </a>
          <a
            href="https://t.me/SaitamaWorldwide"
            target="_blank" // Opens the link in a new tab
            rel="noopener noreferrer" // Security reasons
          >
            <Telegram />
          </a>
          <a
            href="https://www.linkedin.com/company/saitachain/"
            target="_blank" // Opens the link in a new tab
            rel="noopener noreferrer" // Security reasons
          >
            <Linkedin />
          </a>
          <a
            href="https://www.instagram.com/saitachaincoin/"
            target="_blank" // Opens the link in a new tab
            rel="noopener noreferrer" // Security reasons
          >
            <Instagram />
          </a>
          <a
            href="https://www.facebook.com/groups/wearesaitama/?ref=share&mibextid=NSMWBT"
            target="_blank" // Opens the link in a new tab
            rel="noopener noreferrer" // Security reasons
          >
            <Facebook />
          </a>
        </div>
      </motion.div>
      <motion.div
        className="image-section"
        initial={{ opacity: 0, x: 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        {/* <div className="glow-bg">
          <Lottie animationData={glowbg} loop={true} />
        </div> */}
        {/* <video autoPlay loop muted>
          <source
            src="https://saitadex-deepak.s3.us-east-2.amazonaws.com/Videos/2glow_bg.webm"
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video> */}
        <img src="home/social/SocialMediaMain.png" alt="Social Media Icons" />
      </motion.div>
    </motion.div>
  );
};

export default Social;
